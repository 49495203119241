(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name referees.referee.controller:RefereeCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.refereeNewInvoice.invoicegroup.invoice')
    .controller('invoiceprint', invoiceprint);

  function invoiceprint( AclService,$scope, $state,  personafact,lineesfact,Receptores) {
     
   var vm = this;
    vm.isAdm=AclService.isAdmin();
    vm.ctrlName = 'invoiceprint';
   vm.lineesfact=lineesfact;
  
    var base;
          var  s="<style>"+
"* {"+
	"border: 0;"+
	"box-sizing: content-box;"+
	"color: inherit;"+
	"font-family: inherit;"+
	"font-size: inherit;"+
	"font-style: inherit;"+
	"font-weight: inherit;"+
	"line-height: inherit;"+
	"list-style: none;"+
	"margin: 0;"+
	"padding: 0;"+
	"text-decoration: none;"+
	"vertical-align: top;"+
"}"+

/* content editable */

"*[contenteditable] { border-radius: 0.25em; min-width: 1em; outline: 0; }"+

"*[contenteditable] { cursor: pointer; }"+

"*[contenteditable]:hover, *[contenteditable]:focus, td:hover *[contenteditable], td:focus *[contenteditable], img.hover { background: #DEF; box-shadow: 0 0 1em 0.5em #DEF; }"+

"span[contenteditable] { display: inline-block; }"+

/* heading */

"h1 { font: bold 100% sans-serif; letter-spacing: 0.5em; text-align: center; text-transform: uppercase; }"+

/* table */

"table { font-size: 75%; table-layout: fixed; width: 100%; }"+
"table { border-collapse: separate; border-spacing: 2px; }"+
"th, td { border-width: 1px; padding: 0.5em; position: relative; text-align: left; }"+
"th, td { border-radius: 0.25em; border-style: solid; }"+
"th { background: #EEE; border-color: #BBB; }"+
"td { border-color: #DDD; }"+

/* page */

"html { font: 16px/1 'Open Sans', sans-serif; overflow: auto; padding: 0.5in; }"+
"html { background: #999; cursor: default; }"+

"body { box-sizing: border-box; height: 11in; margin: 0 auto; overflow: hidden; padding: 0.5in; width: 8.5in; }"+
"body { background: #FFF; border-radius: 1px; box-shadow: 0 0 1in -0.25in rgba(0, 0, 0, 0.5); }"+

/* header */

"header { margin: 0 0 3em; }"+
"header:after { clear: both; content: \"\"; display: table; }"+

"header h1 { background: #000; border-radius: 0.25em; color: #FFF; margin: 0 0 1em; padding: 0.5em 0; }"+
"header address { float: left; font-size: 75%; font-style: normal; line-height: 1.25; margin: 0 1em 1em 0; }"+
"header address p { margin: 0 0 0.25em; }"+
"header span, header img { display: block; float: right; }"+
"header span { margin: 0 0 1em 1em; max-height: 25%; max-width: 60%; position: relative; }"+
"header img { max-height: 100%; max-width: 100%; }"+
"header input { cursor: pointer; -ms-filter:\"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)\"; height: 100%; left: 0; opacity: 0; position: absolute; top: 0; width: 100%; }"+

/* article */

"article, article address, table.meta, table.inventory { margin: 0 0 3em; }"+
"article:after { clear: both; content: \"\"; display: table; }"+
"article h1 { clip: rect(0 0 0 0); position: absolute; }"+

"article address { float: left; font-size: 100%; font-weight: normal; }"+

/* table meta & balance */

"table.meta, table.balance { float: right; width: 36%; }"+
"table.meta:after, table.balance:after { clear: both; content: \"\"; display: table; }"+

/* table meta */

"table.meta th { width: 60%; }"+
"table.meta td { width: 40%; }"+

/* table items */

"table.inventory { clear: both; width: 100%; }"+
/*"table.inventory th { font-weight: bold; text-align: center; }"+*/

"table.inventory th:nth-child(1) { text-align:  center;width: 64%;font-weight: bold;  }"+
"table.inventory th:nth-child(2) { text-align:  center; width: 12%; font-weight: bold; }"+
"table.inventory th:nth-child(3) { text-align:  center; width: 12%;font-weight: bold;  }"+
"table.inventory th:nth-child(4) { text-align:  center; width: 12%;font-weight: bold;  }"+


"table.inventory td:nth-child(1) { width: 64%; }"+
"table.inventory td:nth-child(2) { text-align: right; width: 12%; }"+
"table.inventory td:nth-child(3) { text-align: right; width: 12%; }"+
"table.inventory td:nth-child(4) { text-align: right; width: 12%; }"+

/* table balance */

"table.balance th, table.balance td { width: 50%; }"+
"table.balance td { text-align: right; }"+

/* aside */

"aside h1 { border: none; border-width: 0 0 1px; margin: 0 0 1em; }"+
"aside h1 { border-color: #999; border-bottom-style: solid; }"+

/* javascript */

".add, .cut"+
"{"+
	"border-width: 1px;"+
	"display: block;"+
	"font-size: .8rem;"+
	"padding: 0.25em 0.5em;	"+
	"float: left;"+
	"text-align: center;"+
	"width: 0.6em;"+
"}"+

".add, .cut"+
"{"+
	"background: #9AF;"+
	"box-shadow: 0 1px 2px rgba(0,0,0,0.2);"+
	"background-image: -moz-linear-gradient(#00ADEE 5%, #0078A5 100%);"+
	"background-image: -webkit-linear-gradient(#00ADEE 5%, #0078A5 100%);"+
	"border-radius: 0.5em;"+
	"border-color: #0076A3;"+
	"color: #FFF;"+
	"cursor: pointer;"+
	"font-weight: bold;"+
	"text-shadow: 0 -1px 2px rgba(0,0,0,0.333);"+
"}"+

".add { margin: -2.5em 0 0; }"+

".add:hover { background: #00ADEE; }"+

".cut { opacity: 0; position: absolute; top: 0; left: -1.5em; }"+
".cut { -webkit-transition: opacity 100ms ease-in; }"+

"tr:hover .cut { opacity: 1; }"+

"@media print {"+
	"* { -webkit-print-color-adjust: exact; }"+
	"html { background: none; padding: 0; }"+
	"body { box-shadow: none; margin: 0; }"+
	"span:empty { display: none; }"+
	".add, .cut { display: none; }"+
"}"+

"@page { margin: 0; }"+
"</style>";
 if (personafact.person.name==null){personafact.person.name="";}
  if (personafact.person.surname==null){personafact.person.surname="";}
   if (personafact.person.secondSurname==null){personafact.person.secondSurname="";}
   if (personafact.person.nif==null){personafact.person.nif="";}
     if (personafact.person.address==null){personafact.person.address="";}
      if (personafact.person.town==null){personafact.person.town="";} 
      if (personafact.person.zipCode==null){personafact.person.zipCode="";}
     
     var recept=Receptores;
    $scope.persona2=personafact;
     var r=0;
      for(var j=0;j<recept.length;j++){
       
        if(recept[j].idReceptor===lineesfact[0].idreceptor){
            r=j;
        }
    }
    var renombre=recept[r].nombre;
    var redireccion=recept[r].direccion;
    var redireccion2=recept[r].direccion2;
    var renif=recept[r].nif;
    var retelf=recept[r].telf;
    
    var date=new Date(vm.lineesfact[0].datafact);
    var day = date.getDate();
  var monthIndex = date.getMonth()+1;
  var year = date.getFullYear();
  var fecha=day+"/"+monthIndex+"/"+year;
 
  base=vm.lineesfact[0].total;
   var irpf3=0;
   if (vm.lineesfact[0].sinirpf!=='1'){
           irpf3=irpf3+0.15*base;}
  for(var i=0;i<vm.lineesfact.length-1;i++){
        
        document.querySelector('table.inventory tbody').appendChild(generateTableRow(vm.lineesfact[i+1],personafact));
           base=base+vm.lineesfact[i+1].total;
           if (vm.lineesfact[i+1].sinirpf!=='1'){
           irpf3=irpf3+0.15*vm.lineesfact[i+1].total;}
    }
    var concepte0=vm.lineesfact[0].esport+" competició JJEE de "+personafact.organisationName+" ";
    
    if (vm.lineesfact[0].sintexto==='1'){
           concepte0=vm.lineesfact[0].esport+" ";
    }
       
      // var irpf=0.15*base;
       var irpf=irpf3;
       var t=irpf.toFixed(2);
       var total=base-t;
       
        var template = document.getElementById('divaprint').innerHTML.replace("persona2.name",personafact.person.name.toUpperCase())
               .replace("persona2.surname1",personafact.person.surname.toUpperCase())
               .replace("persona2.surname2",personafact.person.secondSurname.toUpperCase())
               .replace("persona2.dni",personafact.person.nif.toUpperCase())
               .replace("persona2.adress",personafact.person.address)
               .replace("persona2.poblacio",personafact.person.town)
               .replace("persona2.cp",personafact.person.zipCode)
                .replace("rece2.nombre",renombre)
          .replace("rece2.direccion",redireccion).replace("rece2.direccion2",redireccion2).replace("rece2.nif",renif)
             .replace("rece2.telf",retelf)
               .replace("invoice.numinvoice",vm.lineesfact[0].numinvoice)
               .replace("invoice.data",fecha)
               .replace("persona2.iban",personafact.iban)
               .replace("linea.concepte0",concepte0)
               .replace("linea.partits0",vm.lineesfact[0].numpartits)
                .replace("linea.preu0",currency(vm.lineesfact[0].preu,2, ['.', "'", ',']))
                .replace("linea.total0",currency(vm.lineesfact[0].total,2, ['.', "'", ',']))
                .replace("invoice.base",currency(base,2, ['.', "'", ',']))
                 .replace("invoice.irpf",currency(irpf,2, ['.', "'", ',']))
                 .replace("invoice.total",currency(total,2, ['.', "'", ',']))
                  .replace("invoice.total",currency(total,2, ['.', "'", ',']));
       	
        
       
     var printWin = window.open('', '',
          'left=0, top=0, width=' + screen.availWidth + ', height=' + screen.availHeight
          + ', toolbar=0, scrollbars=0, status=0');
   printWin.document.write(s);
         
   printWin.document.write(template);
   setTimeout(function () {
        printWin.focus();
        printWin.print();
        printWin.close();
      }, 1500);
     window.close();
  }
  
  function generateTableRow(linea,personafact) {
	var emptyColumn = document.createElement('tr');
        var concepte=linea.esport+" competició JJEE de "+personafact.organisationName+" ";
        if (linea.sintexto==='1'){
            concepte=linea.esport+" ";
        }
	emptyColumn.innerHTML = '<td><a class="cut">-</a><span contenteditable>'+concepte+'</span></td>' +
		
                '<td><span contenteditable>'+linea.numpartits+'</span></td>' +
		'<td><span contenteditable>'+currency(linea.preu,2,['.', "'", ','])+'</span><span data-prefix> €</span></td>' +
		
		'<td><span>'+currency(linea.total,2,['.', "'", ','])+'</span><span data-prefix> €</span></td>';
     
	return emptyColumn;
}

function currency(value, decimals, separators) {
    decimals = decimals >= 0 ? parseInt(decimals, 0) : 2;
    separators = separators || ['.', "'", ','];
    var number = (parseFloat(value) || 0).toFixed(decimals);
    if (number.length <= (4 + decimals))
        return number.replace('.', separators[separators.length - 1]);
    var parts = number.split(/[-.]/);
    value = parts[parts.length > 1 ? parts.length - 2 : 0];
    var result = value.substr(value.length - 3, 3) + (parts.length > 1 ?
        separators[separators.length - 1] + parts[parts.length - 1] : '');
    var start = value.length - 6;
    var idx = 0;
    while (start > -3) {
        result = (start > 0 ? value.substr(start, 3) : value.substr(0, 3 + start))
            + separators[idx] + result;
        idx = (++idx) % 2;
        start -= 3;
    }
    return (parts.length == 3 ? '-' : '') + result;
}
}());


